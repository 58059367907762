import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Image from 'next/image';
import NoSsr from '@/components/NoSsr/NoSsr';
import Cookies from 'js-cookie';
import { useAndroidBannersStore } from '@/store/useBannerStore/useAndroidBannerStore';
import * as S from './AndroidAppBanner.styles';

export const AndroidAppBanner = () => {
  const [isAndroidBannerVisible, setIsAndroidBannerVisible] =
    useAndroidBannersStore(state => [
      state.isAndroidBannerVisible,
      state.setIsAndroidBannerVisible,
    ]);
  const { t } = useTranslation();
  const hostnameModified =
    typeof window !== 'undefined' &&
    `.${window.location.hostname?.replace(/^ru./, '')}`;

  function handleCloseClick() {
    Cookies.set('appBannerShow', 'true', {
      expires: 7,
      domain: hostnameModified || '',
    });
    setIsAndroidBannerVisible(false);
  }

  useEffect(() => {
    setIsAndroidBannerVisible(!Cookies.get('appBannerShow'));
  }, []);

  return (
    isAndroidBannerVisible && (
      <NoSsr>
        <S.BannerContainer>
          <S.BannerClose onClick={handleCloseClick}>close</S.BannerClose>
          <S.Banner>
            <Image
              src="/images/app_banners/app-banner-logo.png"
              alt="Tap.Az"
              width={30}
              height={30}
            />
            <S.BannerText>
              {t('banners.app_banner_title')}
              <S.BannerDescription>
                {t('banners.app_banner_desc')}
              </S.BannerDescription>
            </S.BannerText>
          </S.Banner>
          <S.BannerLink
            href="intent://tap.az/#Intent;scheme=tapaz;package=org.tapaz.android;end"
            onClick={handleCloseClick}
          >
            {t('banners.app_banner_open')}
          </S.BannerLink>
        </S.BannerContainer>
      </NoSsr>
    )
  );
};

AndroidAppBanner.displayName = 'AndroidAppBanner';
